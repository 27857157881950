.navigation-container {
  display: flex;
  flex-direction: column;

  /* background-color: aquamarine; */
  background-image: url(./roosevelt_national_park.jpeg);

  /* Full height */
  height: 1000px;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.title-text {
  margin-top: 10%;
  text-align: center;
}

.links-container {
  text-align: center;
}

.nav-link {
  margin-bottom: 10px;
  font-size: 20px;
}

.nav-link:visited {
  color: green;
}
