.about-container {
  width: 80%;
  margin-left: 10px;
}

.link {
  margin-left: 10px;
  margin-bottom: 10px;
}

li {
  margin-bottom: 10px;
}
